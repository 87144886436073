@use '@angular/material' as mat;
body { @include mat.checkbox-density(-2); }

//@include mat.core();
// Available Variables
@import "_variables.scss";

// Relayter icon font
@import "relayter-iconfont.scss";

// Relayter icon font animation
@import "relayter-iconfont-animation.scss";

// Material theme
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

$custom-level: mat.m2-define-typography-level(
    $font-family: $font-family-regular,
    $font-size: $font-size-body,
    $line-height: 1,
    $letter-spacing: normal
);
$custom-button-level: mat.m2-define-typography-level(
    $font-family: $font-family-regular,
    $font-size: $font-size-body,
    $line-height: 1,
    $font-weight: 600,
    $letter-spacing: normal
);
$custom-typography: mat.m2-define-typography-config(
    $font-family: $font-family-regular,
    $subtitle-1: $custom-level,
    $body-1: $custom-level,
    $body-2: $custom-level,
    $button: $custom-button-level
);

@include mat.all-component-typographies($custom-typography);
@include mat.core();

$app-primary-map: (
    50: $color-green,
    100: $color-green,
    200: $color-green,
    300: $color-green,
    400: $color-green,
    500: $color-green,
    600: $color-green,
    700: $color-green,
    800: $color-green,
    900:$color-green,
    A100: $color-green,
    A200: $color-green,
    A400: $color-green,
    A700: $color-green,
    contrast: (
        50: white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: white,
        A200: white,
        A400: white,
        A700: white,
    )
);

$my-palette: (
    50: #e3f2fd,
    100: #bbdefb,
    200: #90caf9,
    300: $color-grey,
    400: #42a5f5,
    500: $color-green,
    600: $color-green,
    700: $color-green,
    800: $color-green,
    900: $color-green,
    A100: #82b1ff,
    A200: $color-green,
    A400: $color-green,
    A700: $color-green,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87),
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: rgba(black, 0.87),
        A200: white,
        A400: white,
        A700: white,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.m2-define-palette($app-primary-map);
$app-accent: mat.m2-define-palette($app-primary-map, A200, A100, A400);
// The warn palette is optional (defaults to red).
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.m2-define-light-theme((
    color: (
        primary: $app-primary,
        accent: $app-accent,
        warn: $app-warn
    ),
    typography: $custom-typography
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);


//mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

$relayter-theme: mat.m2-define-light-theme((
    color: (
        primary: mat.m2-define-palette(mat.$m2-deep-orange-palette),
        secondary: mat.m2-define-palette(mat.$m2-blue-palette),
        forground: mat.m2-define-palette(mat.$m2-yellow-palette),
        background: mat.m2-define-palette($my-palette),
        accent: mat.m2-define-palette($my-palette),
    )
));

@include mat.slide-toggle-color($relayter-theme);
@include mat.checkbox-color($relayter-theme);
@include mat.pseudo-checkbox-color($relayter-theme);
@include mat.radio-density(-1);

// --------------------------------------------------
// Text align
// --------------------------------------------------

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

// size
.small {
    font-size: $font-size-small;
}

// Font Poppins
@font-face {
    font-family: 'Poppins-Bold';
    src: url('/assets/fonts/poppins/poppins-v6-latin-700.woff2') format('woff2'),
    url('/assets/fonts/poppins/poppins-v6-latin-700.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

// Source Sans Pro Bold
@font-face {
    font-family: 'SourceSansPro-Bold';
    src: url('/assets/fonts/source-sans-pro/source-sans-pro-v11-latin-700.woff2') format('woff2'),
    url('/assets/fonts/source-sans-pro/source-sans-pro-v11-latin-700.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

// Source Sans Pro SemiBold
@font-face {
    font-family: 'SourceSansPro-SemiBold';
    src: url('/assets/fonts/source-sans-pro/source-sans-pro-v11-latin-600.woff2') format('woff2'),
    url('/assets/fonts/source-sans-pro/source-sans-pro-v11-latin-600.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

// Source Sans Pro Regular
@font-face {
    font-family: 'SourceSansPro-Regular';
    src: url('/assets/fonts/source-sans-pro/source-sans-pro-v11-latin-regular.woff2') format('woff2'),
    url('/assets/fonts/source-sans-pro/source-sans-pro-v11-latin-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

// --------------------------------------------------
// H tags
// --------------------------------------------------
.hero {
    color: $color-black;
    font-family: $font-family-poppins-bold;
    font-size: $font-size-hero;
    letter-spacing: -.5px;
    margin: 0;
}

h1 {
    color: $color-black;
    font-family: $font-family-bold;
    font-size: $font-size-h1;
    letter-spacing: -.5px;
    margin: 0;
}

h2 {
    color: $color-black;
    font-family: $font-family-bold;
    font-size: $font-size-h2;
    letter-spacing: -.5px;
    margin: 0;
}

h3, h4, h5, h6 {
    color: $color-black;
    font-family: $font-family-bold;
    font-size: $font-size-h3;
    letter-spacing: -.5px;
    margin: 0;
}

.subtitle {
    color: $color-black;
    font-family: $font-family-regular;
    font-size: $font-size-subtitle;
    letter-spacing: -.5px;
    margin: 0;
}

.section-header {
    color: $color-black;
    font-family: $font-family-bold;
    font-size: $font-size-section-header;
    line-height: 1.4;
    margin: 0;
}

.body-strong {
    color: $color-dark-grey;
    font-family: $font-family-semibold;
    font-size: $font-size-body-strong;
}

a, p, body {
    color: $color-grey;
    font-family: $font-family-regular;
    font-size: $font-size-body;
    line-height: 1.4;
    margin: 0;
}

a:hover, a:active {
    color: $color-green !important;
    cursor: pointer;
}

a:active {
    color: $color-black !important;
    cursor: pointer;
}

.mat-cell {
    font-family: $font-family-regular;
    font-size: $font-size-body;
    color: $color-dark-grey;
    line-height: 1.4;
}

::ng-deep.mat-paginator-page-size-label,
::ng-deep.mat-paginator-range-label {
    font-family: $font-family-semibold;
    font-size: $font-size-body;
    color: $color-grey !important;
    height: $input-height;
}

.mat-header-cell,
::ng-deep.mat-slide-toggle-content {
    font-family: $font-family-semibold;
    font-size: $font-size-body;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: $color-black;
}

.mat-mdc-select-arrow {
    border: none !important;
}

// add custom styling to the overlay panel used by the dropdown component without overwriting the global overlay styling.
.dropdown-overlay-panel, .badge-overlay-panel {
    transform: translate(0, $spacer-small) !important;
}

.nuc-dialog-overlay {
    background-color: $color-black-10;
}

.smooth-animated {
    -webkit-transition: $transition-smooth-animated;
    transition: $transition-smooth-animated;
}

// --------------------------------------------------
// Colors
// --------------------------------------------------

.grey {
    color: $color-grey;
}

.light-grey {
    color: $color-light-grey;
}

.grey-70 {
    color: $color-grey-70;
}

.green {
    color: $color-green;
}

.light-green {
    color: $color-light-green;
}

.red {
    color: $color-red;
}

.red-03 {
    color: $color-red-03;
}

.blue {
    color: $color-blue;
}

.yellow {
    color: $color-yellow;
}

.dark-turquoise {
    color: $color-dark-turquoise;
}

.bold-turquoise {
    color: $color-bold-turquoise;
}

.black {
    color: $color-black;
}

.snow {
    color: $color-snow;
}

.dove {
    color: $color-dove;
}

.white {
    color: $color-white;
}

.blue-royal {
    color: $color-blue-royal;
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.filters-overlay-panel {
    background: $color-white;
    border: $border-1-snow;
    border-radius: 3px;
    box-shadow: $box-shadow-regular;
    max-height: $overlay-button-max-height;
    overflow-y: scroll;
    margin-bottom: 12px; // to counter the translateY offset and prevent the bottom being clipped by the window
}

.mat-datepicker-popup {
    margin-top: $spacer-small !important; // the space between input and popup
    // we align datepicker to the end, so we need calculate all the spacers together with toggle width
    // 2 * 12px + 18px
    transform: translateX(52px) !important;
}
