@font-face {
    font-family: 'relayter_iconfont';
    src: url('/assets/fonts/relayter-icon-font/relayter_iconfont.eot?73955391');
    src: url('/assets/fonts/relayter-icon-font/relayter_iconfont.eot?73955391#iefix') format('embedded-opentype'),
    url('/assets/fonts/relayter-icon-font/relayter_iconfont.woff2?73955391') format('woff2'),
    url('/assets/fonts/relayter-icon-font/relayter_iconfont.woff?73955391') format('woff'),
    url('/assets/fonts/relayter-icon-font/relayter_iconfont.ttf?73955391') format('truetype'),
    url('/assets/fonts/relayter-icon-font/relayter_iconfont.svg?73955391#relayter_iconfont') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'relayter_iconfont';
    src: url('/assets/fonts/relayter-icon-font/relayter_iconfont.svg?90425542#relayter_iconfont') format('svg');
  }
}
*/

[class^="nucicon_"]:before, [class*=" nucicon_"]:before {
    font-family: "relayter_iconfont";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.nucicon_layout:before { content: '\e800'; } /* '' */
.nucicon_business_fill:before { content: '\e801'; } /* '' */
.nucicon_calendar:before { content: '\e802'; } /* '' */
.nucicon_dollar:before { content: '\e803'; } /* '' */
.nucicon_cart:before { content: '\e804'; } /* '' */
.nucicon_check_double:before { content: '\e805'; } /* '' */
.nucicon_check_round_fill:before { content: '\e806'; } /* '' */
.nucicon_camera:before { content: '\e807'; } /* '' */
.nucicon_bell_line:before { content: '\e808'; } /* '' */
.nucicon_check_round_line:before { content: '\e809'; } /* '' */
.nucicon_add:before { content: '\e80a'; } /* '' */
.nucicon_add_round_line:before { content: '\e80b'; } /* '' */
.nucicon_remove:before { content: '\e80c'; } /* '' */
.nucicon_bell_fill:before { content: '\e80d'; } /* '' */
.nucicon_trash_line:before { content: '\e80e'; } /* '' */
.nucicon_arrowhead_up:before { content: '\e80f'; } /* '' */
.nucicon_arrowhead_down:before { content: '\e810'; } /* '' */
.nucicon_arrowhead_left:before { content: '\e811'; } /* '' */
.nucicon_arrowhead_right:before { content: '\e812'; } /* '' */
.nucicon_chevron_up:before { content: '\e813'; } /* '' */
.nucicon_chevron_down:before { content: '\e814'; } /* '' */
.nucicon_chevron_left:before { content: '\e815'; } /* '' */
.nucicon_chevron_right:before { content: '\e816'; } /* '' */
.nucicon_trash_fill:before { content: '\e817'; } /* '' */
.nucicon_check:before { content: '\e818'; } /* '' */
.nucicon_clock-_fill:before { content: '\e819'; } /* '' */
.nucicon_clock_line:before { content: '\e81a'; } /* '' */
.nucicon_close_round_fill:before { content: '\e81b'; } /* '' */
.nucicon_close_round_line:before { content: '\e81c'; } /* '' */
.nucicon_close:before { content: '\e81d'; } /* '' */
.nucicon_color:before { content: '\e81e'; } /* '' */
.nucicon_comments_fill:before { content: '\e81f'; } /* '' */
.nucicon_comments_line:before { content: '\e820'; } /* '' */
.nucicon_compare:before { content: '\e821'; } /* '' */
.nucicon_bookmark_fill:before { content: '\e822'; } /* '' */
.nucicon_dots_horizontal:before { content: '\e823'; } /* '' */
.nucicon_dots_vertical:before { content: '\e824'; } /* '' */
.nucicon_download:before { content: '\e825'; } /* '' */
.nucicon_edit:before { content: '\e826'; } /* '' */
.nucicon_education:before { content: '\e827'; } /* '' */
.nucicon_emoji_frown:before { content: '\e828'; } /* '' */
.nucicon_emoji_neutral:before { content: '\e829'; } /* '' */
.nucicon_emoji_smile:before { content: '\e82a'; } /* '' */
.nucicon_explore:before { content: '\e82b'; } /* '' */
.nucicon_eye:before { content: '\e82c'; } /* '' */
.nucicon_files_fill:before { content: '\e82d'; } /* '' */
.nucicon_files_line:before { content: '\e82e'; } /* '' */
.nucicon_fire:before { content: '\e82f'; } /* '' */
.nucicon_flag:before { content: '\e830'; } /* '' */
.nucicon_gear:before { content: '\e831'; } /* '' */
.nucicon_gem_fill:before { content: '\e832'; } /* '' */
.nucicon_gem_line:before { content: '\e833'; } /* '' */
.nucicon_globe:before { content: '\e834'; } /* '' */
.nucicon_heart_fill:before { content: '\e835'; } /* '' */
.nucicon_grid:before { content: '\e836'; } /* '' */
.nucicon_heart_line:before { content: '\e837'; } /* '' */
.nucicon_home:before { content: '\e838'; } /* '' */
.nucicon_template:before { content: '\e839'; } /* '' */
.nucicon_image:before { content: '\e83a'; } /* '' */
.nucicon_images_fill:before { content: '\e83b'; } /* '' */
.nucicon_images_line:before { content: '\e83c'; } /* '' */
.nucicon_label_fill:before { content: '\e83d'; } /* '' */
.nucicon_label_line:before { content: '\e83e'; } /* '' */
.nucicon_loading:before { content: '\e840'; } /* '' */
.nucicon_paperclip:before { content: '\e83f'; } /* '' */
.nucicon_location:before { content: '\e841'; } /* '' */
.nucicon_lock_fill:before { content: '\e842'; } /* '' */
.nucicon_lock_line:before { content: '\e843'; } /* '' */
.nucicon_mail_fill:before { content: '\e844'; } /* '' */
.nucicon_mail_line:before { content: '\e845'; } /* '' */
.nucicon_menu:before { content: '\e846'; } /* '' */
.nucicon_microphone:before { content: '\e847'; } /* '' */
.nucicon_mug:before { content: '\e848'; } /* '' */
.nucicon_network:before { content: '\e849'; } /* '' */
.nucicon_open:before { content: '\e84a'; } /* '' */
.nucicon_phone:before { content: '\e84b'; } /* '' */
.nucicon_plane:before { content: '\e84c'; } /* '' */
.nucicon_question_fill:before { content: '\e84d'; } /* '' */
.nucicon_question_line:before { content: '\e84e'; } /* '' */
.nucicon_remove_fill:before { content: '\e84f'; } /* '' */
.nucicon_remove_line:before { content: '\e850'; } /* '' */
.nucicon_repeat:before { content: '\e851'; } /* '' */
.nucicon_search:before { content: '\e852'; } /* '' */
.nucicon_selector:before { content: '\e853'; } /* '' */
.nucicon_send:before { content: '\e854'; } /* '' */
.nucicon_share:before { content: '\e855'; } /* '' */
.nucicon_size_mm:before { content: '\e856'; } /* '' */
.nucicon_add_round_fill:before { content: '\e857'; } /* '' */
.nucicon_star_fill:before { content: '\e858'; } /* '' */
.nucicon_star_half:before { content: '\e859'; } /* '' */
.nucicon_star_line:before { content: '\e85a'; } /* '' */
.nucicon_thumb_down:before { content: '\e85b'; } /* '' */
.nucicon_upload:before { content: '\e85c'; } /* '' */
.nucicon_user_fill:before { content: '\e85d'; } /* '' */
.nucicon_user_line:before { content: '\e85e'; } /* '' */
.nucicon_volume_off:before { content: '\e85f'; } /* '' */
.nucicon_volume_on:before { content: '\e860'; } /* '' */
.nucicon_warning_fill:before { content: '\e861'; } /* '' */
.nucicon_warning_line:before { content: '\e862'; } /* '' */
.nucicon_duplicate:before { content: '\e863'; } /* '' */
.nucicon_pause:before { content: '\e864'; } /* '' */
.nucicon_play_round:before { content: '\e865'; } /* '' */
.nucicon_play:before { content: '\e866'; } /* '' */
.nucicon_amazon:before { content: '\e867'; } /* '' */
.nucicon_android:before { content: '\e868'; } /* '' */
.nucicon_apple:before { content: '\e869'; } /* '' */
.nucicon_dribbble:before { content: '\e86a'; } /* '' */
.nucicon_dropbox:before { content: '\e86b'; } /* '' */
.nucicon_facebook_messenger:before { content: '\e86c'; } /* '' */
.nucicon_facebook:before { content: '\e86d'; } /* '' */
.nucicon_github:before { content: '\e86e'; } /* '' */
.nucicon_google:before { content: '\e86f'; } /* '' */
.nucicon_instagram:before { content: '\e870'; } /* '' */
.nucicon_invision:before { content: '\e871'; } /* '' */
.nucicon_linkedin:before { content: '\e872'; } /* '' */
.nucicon_pinterest:before { content: '\e873'; } /* '' */
.nucicon_rss:before { content: '\e874'; } /* '' */
.nucicon_skype:before { content: '\e875'; } /* '' */
.nucicon_slack:before { content: '\e876'; } /* '' */
.nucicon_spotify:before { content: '\e877'; } /* '' */
.nucicon_trello:before { content: '\e878'; } /* '' */
.nucicon_twitter:before { content: '\e879'; } /* '' */
.nucicon_vimeo:before { content: '\e87a'; } /* '' */
.nucicon_height:before { content: '\e87b'; } /* '' */
.nucicon_width:before { content: '\e87c'; } /* '' */
.nucicon_filter:before { content: '\e87d'; } /* '' */
.nucicon_bookmark_line:before { content: '\e87e'; } /* '' */
.nucicon_design:before { content: '\e87f'; } /* '' */
.nucicon_print:before { content: '\e880'; } /* '' */
.nucicon_review:before { content: '\e881'; } /* '' */
.nucicon_level_up:before { content: '\e882'; } /* '' */
.nucicon_fullscreen:before { content: '\e883'; } /* '' */
.nucicon_link:before { content: '\e884'; } /* '' */
.nucicon_forward:before { content: '\e885'; } /* '' */
.nucicon_textarea-dragger:before { content: '\e886'; } /* '' */
.nucicon_unlink:before { content: '\e887'; } /* '' */
.nucicon_search_go:before { content: '\e888'; } /* '' */
.nucicon_backward:before { content: '\e889'; } /* '' */
.nucicon_swap:before { content: '\e88a'; } /* '' */
.nucicon_set_after:before { content: '\e88b'; } /* '' */
.nucicon_set_before:before { content: '\e88c'; } /* '' */
.nucicon_px:before { content: '\e88d'; } /* '' */
.nucicon_reset:before { content: '\e88e'; } /* '' */
.nucicon_minimize:before { content: '\e88f';}
.nucicon_maximize:before { content: '\e890';}
.nucicon_drag-and-drop:before { content: '\e891';}
.nucicon_progress:before { content: '\e892';}
.nucicon_connector:before { content: '\e893'; } /* '' */
.nucicon_columns:before { content: '\e894'; }
.nucicon_unlock:before { content: '\e895'; }
.nucicon_sort_asc:before { content: '\e896'; }
.nucicon_sort_dsc:before { content: '\e897'; }
.nucicon_comments_hide:before { content: '\e898'; }
.nucicon_information:before { content: '\e899'; }
.nucicon_comments_show:before { content: '\e89a'; }
.nucicon_comments_popout:before { content: '\e89b'; }
.nucicon_items-list:before { content: '\e89c'; }
.nucicon_data-change:before { content: '\e8a2'; }
.nucicon_sub_information:before { content: '\e89f'; }
.nucicon_document---continues:before { content: '\e8a0'; }
.nucicon_multi-pages:before { content: '\e8a1'; }
.nucicon_code-and:before { content: '\e89e'; }
.nucicon_code-or:before { content: '\e8a3'; }
.nucicon_variant:before { content: '\e89d'; }
.nucicon_stack-down-align-right:before { content: '\e8a4'; }
.nucicon_stack-left-align-bottom:before { content: '\e8a5'; }
.nucicon_stack-right-align-bottom:before { content: '\e8a6'; }
.nucicon_stack-right-align-top:before { content: '\e8a7'; }
.nucicon_stack-left-align-top:before { content: '\e8a8'; }
.nucicon_stack-down-align-left:before { content: '\e8a9'; }
.nucicon_stack-up-align-right:before { content: '\e8aa'; }
.nucicon_stack-up-align-left:before { content: '\e8ab'; }
.nucicon_display-format---stairs-down:before { content: '\e8ac'; }
.nucicon_display-format---stairs-up:before { content: '\e8ad'; }
.nucicon_display-format---grid:before { content: '\e8ae'; }
.nucicon_display-format---wave-up:before { content: '\e8af'; }
.nucicon_display-format---wave-down:before { content: '\e8b0'; }
.nucicon_double-dash:before { content: '\e8b1'; }
.nucicon_ordering_locked:before { content: '\e8b2'; }
.nucicon_ordering_unlocked:before { content: '\e8b3'; }
.nucicon_sort-table:before { content: '\e8b4'; }
.nucicon_regex:before { content: '\e8b5'; }
.nucicon_regex_gm:before { content: '\e8b6'; }
.nucicon_double_checkbox:before { content: '\e8b7'; }
.nucicon_document-fill:before { content: '\e8b8'; }
.nucicon_timeline---note:before { content: '\e8b9'; }
.nucicon_collapse:before { content: '\e8ba'; }
