@import "_variables.scss";

mat-progress-bar {
    .mat-progress-bar-buffer {
        background-color: $color-dove;
    }

    .mat-progress-bar-fill::after {
        background-color: $color-green;
    }
}
