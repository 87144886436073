/*
 Pre app load loading indicator
 */
.pre-load {
    background: #126a66; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #126a66 27%, #1a364f 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #126a66 27%, #1a364f 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #126a66 27%, #1a364f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
}

.pre-load.hidden, .rl-app-loading-indicator.hidden {
    display: none;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.nuc-spin {
    animation: 2s linear infinite spinner;
    font-size: 55px;
    animation-play-state: inherit;
    will-change: transform;
    position: absolute;
}
.rl-app-loading-indicator {
    position: fixed;
    z-index: 8;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 200px;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: $color_white;
    display: flex;
    flex-direction: column;
}
