$transition-smooth-animated: all 0.2s ease-in-out;

$transition-all-short: all 230ms;
$transition-border-color-short: border-color 230ms;
$transition-background-color-short: background-color 230ms;
$transition-all-buttons: all 150ms;
$transition-opacity-short: opacity 250ms;
$transition-width-short: width 250ms;
$transition-tab-bar: all 0.3s ease-in-out;
$transition-card-view: box-shadow 0.3s ease-in-out;
