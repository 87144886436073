@import "_transitions.scss";
@import "_colors.scss";
//
// Project Variables
// --------------------------------------------------
// FONT
$font-family-poppins-bold: 'Poppins-Bold', sans-serif;

$font-family-bold: 'SourceSansPro-Bold', sans-serif;
$font-family-semibold: 'SourceSansPro-SemiBold', sans-serif;
$font-family-regular: 'SourceSansPro-Regular', sans-serif;

// FONT SIZES
$font-size-hero: 2rem;               // 32px
$font-size-h1: 1.125rem;             // 18px
$font-size-h2: 1rem;                 // 16px
$font-size-h3: .875rem;              // 14px

$font-size-subtitle: 1rem;           // 16px
$font-size-section-header: .875rem;  // 14px

$font-size-body: .875rem;            // 14px
$font-size-body-strong: .875rem;     // 14px
$font-size-small: .75rem;            // 12px

$letter-spacing: 0;

// Border
$border-radius-default: 3px;
$border-radius-badge: 14px;
$border-card-radius: 10px;
$border-radius-lg: .3rem;
$border-width: 1px;
$border-color-normal: $color-grey;
$border-color-focus: $color-grey;
$border-1-snow: 1px solid $color-snow;
$tab-border-bottom: 1px solid $color-snow;

// Box shadow
$box-shadow-regular: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 6px 10px 0 rgba(0, 0, 0, 0.1);
$box-shadow-active: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
$box-shadow-dropdown: 0 3px 9px 0 rgba(0, 0, 0, 0.05), 0 0 2px 0 rgba(0, 0, 0, 0.12);
$box-shadow-progress-bar: $box-shadow-dropdown;

// --- Sizes

// Spacing
$spacer-tiny: 0.1875rem;         // 3px
$spacer-small: 0.375rem;         // 6px
$spacer-base: 0.75rem;           // 12px
$spacer-large: 1.5rem;           // 24px
$spacer-extra-large: 3rem;       // 48px
$spacer-extra-extra-large: 6rem; // 96px

// Inputs
$input-height: 32px;

$icon-button-height: 16px;
$nuc-button-icon-width: 35px;
$checkbox-height: 20px;
$radiobutton-height: 20px;

$badge-height: 24px;

// dropdown related
$dropdown-options-margin-top: calc($input-height + $spacer-small);   // generally, if it's button dropdown option, it needs to be 33px, because button is 27px already

// Card views
$card-view-width-medium: 180px;
$card-view-width-large: 230px;
$card-view-width-extra-large: 426px;
$card-view-width-small: 116px;

$card-view-height-medium: 120px;
$card-view-height-large: 136px;
$card-view-height-extra-large: 284px;
$card-view-height-small: 78px;

// Tooltip
$tooltip-horizontal-padding: 8px; // TODO: jiamei check if we need to change this
$tooltip-vertical-padding: $spacer-tiny;
$tooltip-padding: $tooltip-vertical-padding $tooltip-horizontal-padding;
$tooltip-margin: $spacer-tiny;
$tooltip-max-width: 250px;

// Icon size
$icon-size-cardview: 30px;
$button-icon-size: .875rem;
$icon-radio-height: 128px;

// Elevations
$elevation-05-DP: 0 3px 9px 0 rgba(0, 0, 0, 0.05), 0 0 2px 0 rgba(0, 0, 0, 0.12);
$elevation-1-DP: 0 4px 20px 0 rgba(0, 0, 0, 0.14);

// Height
$progress-bar-height: 1.5rem;

// Toasts
$toast-width: 450px;
$toast-gap: $spacer-small;

// Menu
$side-menu-width: 78px;

// Overlay button template container
$overlay-button-max-height: 444px;

// Default search bar width
$search-bar-width: 450px;

// full modal
$full-modal-horizontal-padding: 108px;
$full-modal-footer-height: 80px;
$full-modal-header-height: 88px;
